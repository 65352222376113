import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { NUM_MONTHS, START_MONTH } from "../../constants";
import { updateConfig } from "../../redux/config-slice";
import { formatMonthDate } from "../../utils/date";
const { RangePicker } = DatePicker;

const MONTHS_IN_QUARTER = 3;

export const DateFilter = () => {
  const dates = useSelector((state) => state.config.dates);
  const dispatch = useDispatch();
  const startDate = dayjs().set("month", START_MONTH);
  const endDate = startDate.add(NUM_MONTHS - 1, "month");
  return (
    <RangePicker
      picker="month"
      value={dates}
      disabledDate={(date) =>
        formatMonthDate(date) < formatMonthDate(startDate) || formatMonthDate(date) > formatMonthDate(endDate)
      }
      presets={[...Array(NUM_MONTHS / MONTHS_IN_QUARTER)].map((_, i) => {
        const startMonth = START_MONTH + i * MONTHS_IN_QUARTER;
        return {
          label: `Q${i + 1}`,
          value: [dayjs().set("month", startMonth), dayjs().set("month", startMonth + MONTHS_IN_QUARTER - 1)],
        };
      })}
      onChange={(dates) => dispatch(updateConfig({ dates }))}
    />
  );
};
