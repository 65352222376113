import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../utils/fetch";

export const login = createAsyncThunk("user/login", async (credentials, { rejectWithValue }) => {
  try {
    const { businessUnit } = await post("login", credentials);
    return { username: credentials.username, businessUnit };
  } catch (err) {
    return rejectWithValue(err);
  }
});

const userSlice = createSlice({
  name: "user",
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export default userSlice.reducer;
