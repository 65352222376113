import { Space } from "antd";
import { DraftMode } from "./draft-mode";
import { Reset } from "./reset";
import { ScenarioAnalysis } from "./scenario-analysis";

export const Tools = () => (
  <Space>
    <ScenarioAnalysis />
    <DraftMode />
    <Reset />
  </Space>
);
