import { Button, Form, Input } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { tables } from "../../constants/tables";
import { fetchAll } from "../../redux/store";
import { login } from "../../redux/user-slice";
import styles from "./index.module.css";

export const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Form
      className={styles.container}
      layout="inline"
      initialValues={{ remember: true }}
      onFinish={(credentials) => {
        dispatch(login(credentials)).then(() => {
          fetchAll();
          navigate(`/tables?table=${Object.keys(tables)[0]}`);
        });
      }}
    >
      <Form.Item label="Username" name="username">
        <Input />
      </Form.Item>
      <Form.Item label="Password" name="password" rules={[{ required: true, message: "Please input your password!" }]}>
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};
