import { Button, Form, Input } from "antd";
import { useDispatch } from "react-redux";
import { addTagCategory } from "../../redux/tag-categories-slice";

export const CreateTagCategory = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  return (
    <Form
      form={form}
      layout="inline"
      requiredMark={false}
      onFinish={({ tagCategory }) => {
        dispatch(addTagCategory(tagCategory));
        form.resetFields();
      }}
    >
      <Form.Item
        label="New Tag Category"
        name="tagCategory"
        rules={[{ required: true, message: "Please input a tag category name!" }]}
      >
        <Input placeholder="Enter a new category..." />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Create
        </Button>
      </Form.Item>
    </Form>
  );
};
