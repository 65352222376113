import { Select } from "antd";
import { useSelector } from "react-redux";
import styles from "./index.module.css";

export const TagsSelect = (props) => {
  const tags = useSelector((state) => state.tags);
  const tagCategories = useSelector((state) => state.tagCategories);
  const categoriesMap = Object.fromEntries(tagCategories.map(({ value, label }) => [value, label]));
  return (
    <Select
      className={styles.container}
      mode="multiple"
      placeholder="Select tags..."
      filterOption={(searchText, { label }) => label.includes(searchText)}
      options={Object.entries(
        tags.reduce((acc, { category, ...tag }) => {
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push(tag);
          return acc;
        }, {})
      ).map(([category, options]) => ({ label: categoriesMap[category], options }))}
      {...props}
    />
  );
};
