import { message } from "antd";
import axios from "axios";

axios.defaults.baseURL = "/api/";

export const [get, post, patch, del] = ["get", "post", "patch", "delete"].map(
  (method) => (path, payload) =>
    axios({
      method,
      url: path,
      data: payload,
    })
      .then((response) => response.data)
      .catch((err) => {
        const response = (err || {}).response || {};
        if (response.status === 401) {
          window.location.replace("/");
        }
        message.error(response.data, 5);
        throw err;
      })
);
