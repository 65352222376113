import { Button, Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { addTag } from "../../redux/tags-slice";
import styles from "./create-tag.module.css";

export const CreateTag = () => {
  const [form] = Form.useForm();
  const categories = useSelector((state) => state.tagCategories);
  const dispatch = useDispatch();
  return (
    <Form
      form={form}
      layout="inline"
      requiredMark={false}
      onFinish={(data) => {
        dispatch(addTag(data));
        form.resetFields();
      }}
    >
      <Form.Item label="New Tag" name="label" rules={[{ required: true, message: "Please input a tag name!" }]}>
        <Input placeholder="Enter a new tag..." />
      </Form.Item>
      <Form.Item label="Category" name="category" rules={[{ required: true, message: "Please input a tag category!" }]}>
        <Select className={styles.category} options={categories} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Create
        </Button>
      </Form.Item>
    </Form>
  );
};
