import { createSlice } from "@reduxjs/toolkit";

const configSlice = createSlice({
  name: "config",
  initialState: {},
  reducers: {
    updateConfig: (state, action) => ({ ...state, ...action.payload }),
    resetConfig: () => ({}),
  },
});

export const { updateConfig, resetConfig } = configSlice.actions;

export default configSlice.reducer;
