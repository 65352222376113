import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { NUM_MONTHS, START_MONTH } from "../../constants";
import { tables } from "../../constants/tables";
import { updateData } from "../../redux/data-slice";
import { formatDate, formatMonthDate } from "../../utils/date";
import { AgGrid, numberFormatter } from "../ag-grid";
import styles from "./index.module.css";

const computeTotal = ({ data }) => {
  const { index, client, ...rest } = data;
  return Object.values(rest).reduce((sum, e) => sum + (isNaN(e) ? 0 : Number(e)), 0);
};

export const ForecastTable = ({ className, table }) => {
  const dispatch = useDispatch();

  const tablesData = useSelector((state) => state.data);
  const tableConfig = tables[table];
  const rows = tableConfig?.rows;

  const businessUnit = useSelector((state) => state.user.businessUnit);
  const selectedTags = useSelector((state) => state.config.tags);
  const tags = useSelector((state) => state.tags);
  const categoriesByTag = Object.fromEntries(tags.map(({ value, category }) => [value, category]));
  const allClients = useSelector((state) => state.clients);
  const selectedClients = useSelector((state) => state.config.clients);

  const clients = Object.fromEntries(
    Object.entries(allClients).filter(
      ([clientId, { businessUnits, tags }]) =>
        businessUnits.includes(businessUnit) &&
        (!selectedClients?.length || selectedClients?.includes(clientId)) &&
        (!selectedTags?.length ||
          Object.values(
            selectedTags.reduce((acc, tag) => {
              const category = categoriesByTag[tag];
              if (!acc[category]) {
                acc[category] = [];
              }
              acc[category].push(tag);
              return acc;
            }, {})
          ).every((categoryTags) => categoryTags.some((tag) => tags.includes(tag))))
    )
  );

  const [startDate, endDate] = useSelector((state) => state.config.dates) || [];
  const dates = [...Array(NUM_MONTHS)]
    .map((_, i) =>
      dayjs()
        .set("month", START_MONTH + i)
        .set("date", 1)
    )
    .filter(
      (date) =>
        (!startDate || formatMonthDate(date) >= formatMonthDate(startDate)) &&
        (!endDate || formatMonthDate(date) <= formatMonthDate(endDate))
    );

  const data = Object.entries(rows || clients).map(([client, config]) => {
    const valueGetter = tableConfig.valueGetter || config.valueGetter;
    return {
      client,
      ...Object.fromEntries(
        valueGetter
          ? dates.map((date) => {
              const field = formatDate(date);
              return [field, valueGetter(field, tablesData, client)];
            })
          : Object.entries(tablesData[table]?.[client] || {}).map(([date, data]) => [date, data.value])
      ),
    };
  });

  const valueFormatter = ({ value, data }) =>
    (tableConfig.valueFormatter || rows?.[data.client]?.valueFormatter || numberFormatter)({ value });

  return (
    <div className={`${styles.container} ${className}`}>
      <h3 className={styles.title}>
        {tableConfig.title}
        {tableConfig.description && (
          <>
            {" "}
            <Tooltip title={tableConfig.description}>
              <InfoCircleOutlined />
            </Tooltip>
          </>
        )}
      </h3>
      <AgGrid
        suppressMovableColumns
        domLayout={null}
        rowData={data}
        pinnedBottomRowData={
          !tableConfig.rows && [
            {
              client: tableConfig.aggAvg ? "avg" : "total",
              ...Object.fromEntries(
                dates.map((date) => {
                  const field = formatDate(date);
                  let value = data.reduce((acc, row) => acc + (row[field] || 0), 0);
                  if (tableConfig.aggAvg) {
                    value /= Object.keys(clients).length;
                  }
                  return [field, value];
                })
              ),
            },
          ]
        }
        onCellValueChanged={({ data, colDef }) => {
          dispatch(
            updateData({
              table,
              client: data.client,
              value: data[colDef.field],
              date: colDef.field,
            })
          );
        }}
        columnDefs={[
          {
            headerName: "SI.No",
            menuTabs: [],
            pinned: "left",
            valueGetter: ({ node, data }) => (["total", "avg"].includes(data["client"]) ? "" : node.rowIndex + 1),
          },
          {
            headerName: "Client",
            field: "client",
            pinned: "left",
            refData: {
              ...{ total: "Total", avg: "Average" },
              ...Object.fromEntries(Object.entries({ ...rows, ...clients }).map(([key, { name }]) => [key, name])),
            },
          },
          ...dates.map((date) => ({
            headerName: date.format("MMM"),
            field: formatDate(date),
            editable: ({ data, colDef }) =>
              !["total", "avg"].includes(data["client"]) &&
              (tableConfig.isEditable?.(colDef.field) ||
                (!tableConfig.valueGetter && !rows?.[data.client].valueGetter)),
            filter: "agNumberColumnFilter",
            valueSetter: ({ data, colDef, newValue }) => {
              data[colDef.field] = newValue && Number(newValue);
              return true;
            },
            valueFormatter,
          })),
          {
            headerName: "Average",
            field: "avg",
            pinned: "right",
            valueGetter: ({ data }) => {
              const total = computeTotal({ data });
              return Math.round((total / NUM_MONTHS) * 100) / 100;
            },
            valueFormatter,
          },
          {
            headerName: "Total",
            field: "total",
            pinned: "right",
            valueGetter: computeTotal,
            valueFormatter,
          },
        ]}
      />
    </div>
  );
};
