import { Button, Form, Input } from "antd";
import { useDispatch } from "react-redux";
import { addClient } from "../../redux/clients-slice";

export const AddClient = () => {
  const dispatch = useDispatch();
  return (
    <Form layout="inline" requiredMark={false} onFinish={({ client }) => dispatch(addClient(client))}>
      <Form.Item label="New Client" name="client" rules={[{ required: true, message: "Please input a client name!" }]}>
        <Input placeholder="Enter a new client..." />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Create
        </Button>
      </Form.Item>
    </Form>
  );
};
