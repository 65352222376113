import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateConfig } from "../../redux/config-slice";
import styles from "./clients-filter.module.css";

export const ClientsFilter = () => {
  const clients = useSelector((state) => state.clients);
  const selectedClients = useSelector((state) => state.config.clients);
  const dispatch = useDispatch();
  return (
    <Select
      className={styles.container}
      mode="multiple"
      placeholder="Select clients..."
      options={Object.entries(clients).map(([key, client]) => ({ value: key, label: client.name }))}
      onChange={(selectedClients) => dispatch(updateConfig({ clients: selectedClients, tags: [] }))}
      value={selectedClients}
    />
  );
};
