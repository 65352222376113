import { ProLayout } from "@ant-design/pro-layout";
import { Space } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PARAM_TABLE } from "../../constants/search-params";
import { Clients } from "../clients";
import { TableMenu } from "../table-menu";
import { Tables } from "../tables";
import { TagCategories } from "../tag-categories";
import { Tags } from "../tags";
import { User } from "../user";

export const MainView = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tables = searchParams.getAll(PARAM_TABLE);

  return (
    <ProLayout
      logo="/logo.jpeg"
      title="Business Intelligence"
      layout="top"
      onMenuHeaderClick={() => navigate("/")}
      rightContentRender={() => (
        <Space>
          <TableMenu />
          <Clients />
          <Tags />
          <TagCategories />
          <User />
        </Space>
      )}
    >
      <Tables tables={tables} />
    </ProLayout>
  );
};
