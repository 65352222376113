import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

export const AgGrid = (props) => {
  const exportParams = {
    processHeaderCallback: ({ column }) => column.colId,
    processCellCallback: ({ column, value }) => column.colDef.refData?.[value] ?? value,
  };
  return (
    <AgGridReact
      defaultCsvExportParams={exportParams}
      defaultExcelExportParams={exportParams}
      domLayout="autoHeight"
      singleClickEdit
      enableRangeSelection
      onGridSizeChanged={({ columnApi }) => columnApi.autoSizeAllColumns()}
      onColumnResized={({ columnApi }) => columnApi.autoSizeAllColumns()}
      {...props}
      className={props.className + " ag-theme-alpine"}
      defaultColDef={{ sortable: true, filter: true, resizable: true, ...props.defaultColDef }}
    />
  );
};

export const percentFormatter = ({ value }) => (isNaN(value) ? null : `${Math.round(value * 100)}%`);
export const numberFormatter = ({ value }) => (isNaN(value) ? value : Math.round(value * 10) / 10);
export const currencyFormatter = ({ value }) =>
  isNaN(value) ? value : numberFormatter({ value }).toLocaleString("en-IN");
