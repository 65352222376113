import { Button, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateConfig } from "../../redux/config-slice";

export const DraftMode = () => {
  const draftMode = useSelector((state) => state.config.draftMode);
  const dispatch = useDispatch();
  return (
    <Tooltip title="Changes will not be saved in draft mode.">
      <Button danger={draftMode} onClick={() => dispatch(updateConfig({ draftMode: !draftMode }))}>
        {draftMode && "Exit"} Draft Mode
      </Button>
    </Tooltip>
  );
};
