import { FlexLayout } from "../flex-layout";
import { ForecastTable } from "../forecast-table";
import { Search } from "../search";
import { Tools } from "../tools";
import styles from "./index.module.css";

export const Tables = ({ tables }) => {
  return (
    <div className={styles.container}>
      <FlexLayout justifyContent="space-between" className={`${styles.rightMargin} ${styles.searchBar}`}>
        <Search />
        <Tools />
      </FlexLayout>
      <FlexLayout style={{ width: `${tables.length * 100}%` }}>
        {tables.map((table) => (
          <ForecastTable className={styles.rightMargin} key={table} table={table} />
        ))}
      </FlexLayout>
    </div>
  );
};
