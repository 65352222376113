import { Space } from "antd";
import { ClientsFilter } from "./clients-filter";
import { DateFilter } from "./date-filter";
import { TagsFilter } from "./tags-filter";

export const Search = () => {
  return (
    <Space>
      <DateFilter />
      <ClientsFilter />
      <TagsFilter />
    </Space>
  );
};
