import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import storage from "redux-persist/lib/storage";
import clientsSlice, { fetchClients } from "./clients-slice";
import configSlice from "./config-slice";
import dataSlice, { fetchData } from "./data-slice";
import tagCategoriesSlice, { fetchTagCategories } from "./tag-categories-slice";
import tagsSlice, { fetchTags } from "./tags-slice";
import userSlice from "./user-slice";

const persistedReducer = persistReducer(
  { key: "root", storage, blacklist: ["config"] },
  combineReducers({
    clients: clientsSlice,
    data: dataSlice,
    user: userSlice,
    config: configSlice,
    tags: tagsSlice,
    tagCategories: tagCategoriesSlice,
  })
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

persistStore(store);

export const fetchAll = () => {
  store.dispatch(fetchClients());
  store.dispatch(fetchData());
  store.dispatch(fetchTags());
  store.dispatch(fetchTagCategories());
};

export const getCookieValue = (name) => document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

if (getCookieValue("password")) {
  fetchAll();
}
