import { Form, Select, Space } from "antd";
import { useSearchParams } from "react-router-dom";
import { PARAM_TABLE } from "../../constants/search-params";
import { tables } from "../../constants/tables";
import styles from "./index.module.css";

export const TableMenu = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTables = searchParams.getAll(PARAM_TABLE);
  return (
    <Space size="large">
      {[
        { label: "Input Tables", showInputTables: true },
        { label: "Output Tables", showInputTables: false },
      ].map(({ label, showInputTables }) => {
        const visibleTables = Object.entries(tables).filter(
          ([, table]) => !(table.valueGetter || table.rows) === showInputTables
        );
        const tablesMap = Object.fromEntries(visibleTables);

        return (
          <Form.Item key={label} label={label} colon={false} className={styles.form}>
            <Select
              className={styles.select}
              options={visibleTables.map(([key, table]) => ({
                label: table.title,
                value: key,
              }))}
              mode="multiple"
              placeholder="Start typing to search tables..."
              value={activeTables?.filter((table) => tablesMap[table])}
              onChange={(values) => {
                searchParams.delete(PARAM_TABLE);
                [...values, ...activeTables?.filter((table) => !tablesMap[table])].forEach((table) => {
                  searchParams.append(PARAM_TABLE, table);
                });
                setSearchParams(searchParams);
              }}
            />
          </Form.Item>
        );
      })}
    </Space>
  );
};
