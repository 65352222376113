import { Button, Modal } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteTagCategory } from "../../redux/tag-categories-slice";
import { formatDate } from "../../utils/date";
import { AgGrid } from "../ag-grid";
import { CreateTagCategory } from "./create-tag-category";
import styles from "./index.module.css";

export const TagCategories = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const categories = useSelector((state) => state.tagCategories);
  const dispatch = useDispatch();

  return (
    <div className={styles.container}>
      <Button type="primary" onClick={() => setIsModalOpen(true)}>
        Tag Categories
      </Button>

      <Modal open={isModalOpen} title="Tag Categories" footer={null} onCancel={() => setIsModalOpen(false)}>
        <AgGrid
          className={styles.grid}
          rowData={categories}
          columnDefs={[
            {
              headerName: "Tag Category",
              field: "label",
            },
            {
              headerName: "Created On",
              field: "createdOn",
              valueFormatter: ({ value }) => formatDate(value),
            },
            {
              headerName: "Actions",
              field: "value",
              cellRenderer: ({ value }) => (
                <Button danger onClick={() => dispatch(deleteTagCategory(value))}>
                  Delete
                </Button>
              ),
            },
          ]}
        />
        <CreateTagCategory />
      </Modal>
    </div>
  );
};
