import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { del, get, post } from "../utils/fetch";

export const fetchTags = createAsyncThunk("tags/fetch", async (_, { rejectWithValue }) => {
  try {
    const tags = await get("tags");
    return tags.map(({ _id, ...tag }) => ({ value: _id, ...tag }));
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const addTag = createAsyncThunk("tags/add", async (data, { rejectWithValue }) => {
  try {
    const tag = await post("tags", data);
    return { ...tag, ...data };
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteTag = createAsyncThunk("tags/delete", async (value, { rejectWithValue }) => {
  try {
    await del(`tags/${value}`);
    return { value };
  } catch (err) {
    return rejectWithValue(err);
  }
});

const tagsSlice = createSlice({
  name: "tags",
  initialState: [],
  extraReducers: (builder) => {
    builder.addCase(fetchTags.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(addTag.fulfilled, (state, { payload }) => {
      state.push({ value: payload.insertedId, ...payload });
    });
    builder.addCase(deleteTag.fulfilled, (state, { payload }) => {
      return state.filter((tag) => tag.value !== payload.value);
    });
  },
});

export default tagsSlice.reducer;
