import { Button, Modal, Slider } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { scenarioTables } from "../../constants/scenario-tables";
import { analyzeMultiplier } from "../../redux/data-slice";
import styles from "./scenario-analysis.module.css";

export const ScenarioAnalysis = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const data = useSelector((state) => state.data);
  const dispatch = useDispatch();

  return (
    <>
      <Button onClick={() => setIsModalOpen(true)}>Scenario Analysis</Button>
      <Modal
        width={700}
        open={isModalOpen}
        title="Scenario Analysis"
        footer={null}
        onCancel={() => setIsModalOpen(false)}
      >
        {scenarioTables.map(({ label, key }) => (
          <div key={key} className={styles.sliderContainer}>
            <h4 className={styles.label}>{label}</h4>
            <Slider
              step={0.1}
              min={-0.5}
              max={0.5}
              value={
                (Object.values(data[key] || {})
                  .map((dates) => Object.values(dates).map(({ value, inputValue }) => value / inputValue))
                  .flat()
                  .find((value) => !isNaN(value)) ?? 1) - 1
              }
              onChange={(value) => dispatch(analyzeMultiplier({ table: key, multiplier: 1 + value }))}
              marks={Object.fromEntries(
                [...Array(11)].map((_, i) => {
                  const value = (i - 5) / 10;
                  return [
                    value,
                    {
                      label: `${value > 0 ? "+" : ""}${value * 100}%`,
                      style: {
                        color: value < 0 ? "red" : value === 0 ? "black" : "green",
                        fontWeight: value === 0 && "bold",
                      },
                    },
                  ];
                })
              )}
            />
          </div>
        ))}
      </Modal>
    </>
  );
};
