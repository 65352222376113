import { useDispatch, useSelector } from "react-redux";
import { updateConfig } from "../../redux/config-slice";
import { TagsSelect } from "../tags-select";
import styles from "./tags-filter.module.css";

export const TagsFilter = () => {
  const tags = useSelector((state) => state.config.tags);
  const dispatch = useDispatch();
  return (
    <TagsSelect
      className={styles.container}
      onChange={(newTags) => dispatch(updateConfig({ tags: newTags, clients: [] }))}
      value={tags}
    />
  );
};
