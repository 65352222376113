import { Button, Modal } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteClient, updateClient } from "../../redux/clients-slice";
import { formatDate } from "../../utils/date";
import { AgGrid } from "../ag-grid";
import { TagsSelect } from "../tags-select";
import { AddClient } from "./add-client";
import styles from "./index.module.css";

export const Clients = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const businessUnit = useSelector((state) => state.user.businessUnit);
  const clients = useSelector((state) => state.clients);
  const dispatch = useDispatch();

  return (
    <div className={styles.container}>
      <Button type="primary" onClick={() => setIsModalOpen(true)}>
        Clients
      </Button>

      <Modal width={"80%"} open={isModalOpen} title="Clients" footer={null} onCancel={() => setIsModalOpen(false)}>
        <AgGrid
          className={styles.grid}
          rowData={Object.entries(clients).map(([id, client]) => ({ id, ...client }))}
          columnDefs={[
            {
              headerName: "Client",
              field: "name",
              editable: true,
              onCellValueChanged: ({ newValue, data }) => dispatch(updateClient({ id: data.id, name: newValue })),
            },
            {
              headerName: "Created On",
              field: "createdOn",
              valueFormatter: ({ value }) => formatDate(value),
            },
            {
              headerName: "Tags",
              field: "tags",
              cellRenderer: ({ value, data }) => (
                <TagsSelect
                  value={value}
                  onChange={(newTags) => dispatch(updateClient({ id: data.id, tags: newTags }))}
                />
              ),
            },
            {
              headerName: "Actions",
              field: "id",
              cellRenderer: ({ value, data }) =>
                data.businessUnits?.includes(businessUnit) ? (
                  <Button danger onClick={() => dispatch(deleteClient({ id: value, businessUnit }))}>
                    Delete from current BU
                  </Button>
                ) : (
                  <Button onClick={() => dispatch(updateClient({ id: value, businessUnit }))}>Add to current BU</Button>
                ),
            },
          ]}
        />
        <AddClient />
      </Modal>
    </div>
  );
};
