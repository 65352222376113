import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, post } from "../utils/fetch";

export const fetchData = createAsyncThunk("data/fetch", async (_, { rejectWithValue }) => {
  try {
    const data = await get("data");
    return data.reduce((acc, { table, client, date, ...rest }) => {
      if (!(table in acc)) {
        acc[table] = {};
      }

      if (!(client in acc[table])) {
        acc[table][client] = {};
      }

      acc[table][client][date] = rest;
      return acc;
    }, {});
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateData = createAsyncThunk("data/update", async (payload, { rejectWithValue, getState }) => {
  try {
    const {
      config: { draftMode },
    } = getState();
    if (!draftMode) {
      await post("data", payload);
    }
    return payload;
  } catch (err) {
    return rejectWithValue(err);
  }
});

const dataSlice = createSlice({
  name: "data",
  initialState: {},
  reducers: {
    analyzeMultiplier: (state, { payload: { table, multiplier } }) => {
      Object.values(state[table] || {}).forEach((dates) =>
        Object.values(dates).forEach((data) => {
          if (typeof data.value !== "number") {
            return;
          }
          data.inputValue ??= data.value;
          data.value = data.inputValue * multiplier;
        })
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(updateData.fulfilled, (state, { payload }) => {
      const { table, client, date, ...data } = payload;
      if (!(table in state)) {
        state[table] = {};
      }
      if (!(client in state[table])) {
        state[table][client] = {};
      }

      state[table][client][date] = data;
    });
  },
});

export const { analyzeMultiplier } = dataSlice.actions;

export default dataSlice.reducer;
