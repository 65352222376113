import { Button, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { scenarioTables } from "../../constants/scenario-tables";
import { resetConfig } from "../../redux/config-slice";
import { analyzeMultiplier } from "../../redux/data-slice";

export const Reset = () => {
  const dispatch = useDispatch();
  return (
    <Tooltip title="Reset all configs">
      <Button
        onClick={() => {
          dispatch(resetConfig());
          scenarioTables.forEach(({ key }) => dispatch(analyzeMultiplier({ table: key, multiplier: 1 })));
        }}
      >
        Reset
      </Button>
    </Tooltip>
  );
};
