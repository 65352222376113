import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { del, get, patch, post } from "../utils/fetch";

export const fetchClients = createAsyncThunk("clients/fetch", async (_, { rejectWithValue }) => {
  try {
    const clients = await get("clients");
    return Object.fromEntries(clients.map(({ _id, ...client }) => [_id, client]));
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const addClient = createAsyncThunk("clients/add", async (name, { rejectWithValue, getState }) => {
  try {
    const {
      user: { businessUnit },
    } = getState();
    const client = await post("clients", { name, businessUnit });
    return { ...client, name, businessUnit };
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateClient = createAsyncThunk(
  "clients/update",
  async ({ id, businessUnit, ...client }, { rejectWithValue }) => {
    try {
      await patch(`clients/${id}`, client);
      return { id, businessUnit, ...client };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteClient = createAsyncThunk("clients/delete", async ({ id, businessUnit }, { rejectWithValue }) => {
  try {
    const { deleted } = await del(`clients/${id}`);
    return { id, businessUnit, deleted };
  } catch (err) {
    return rejectWithValue(err);
  }
});

const clientsSlice = createSlice({
  name: "clients",
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(fetchClients.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(addClient.fulfilled, (state, { payload }) => {
      state[payload.insertedId] = { name: payload.name, businessUnits: [payload.businessUnit] };
    });
    builder.addCase(updateClient.fulfilled, (state, { payload: { id, businessUnit, ...client } }) => {
      if (businessUnit) {
        state[id].businessUnits.push(businessUnit);
      } else {
        state[id] = { ...state[id], ...client };
      }
    });
    builder.addCase(deleteClient.fulfilled, (state, { payload }) => {
      if (payload.deleted) {
        delete state[payload.id];
      } else {
        const { businessUnits } = state[payload.id];
        businessUnits.splice(businessUnits.indexOf(payload.businessUnit), 1);
      }
    });
  },
});

export default clientsSlice.reducer;
