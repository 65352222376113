import dayjs from "dayjs";
import { START_MONTH } from ".";
import { currencyFormatter, percentFormatter } from "../components/ag-grid";
import { formatDate } from "../utils/date";

const getPreviousMonth = (date) => formatDate(dayjs(date).subtract(1, "month"));

const areValid = (...nums) => nums.every((num) => !isNaN(num));

const sum = (values) => (areValid(...values) ? values.reduce((sum, val) => sum + val, 0) : null);
const subtract = ([v1, v2]) => (areValid(v1, v2) ? v1 - v2 : null);
const divide = ([v1, v2]) => (areValid(v1, v2) ? v1 / v2 : null);
const getFirst = ([val]) => val;

const isFirstMonth = (date) => dayjs(date).month() === START_MONTH;

const computeTotal = (date, tableData) =>
  Object.values(tableData || {}).reduce((sum, dates) => sum + (dates[date]?.value ?? 0), 0);

const computeTotalWith = (date, data, computeFn, ...fields) => {
  const values = fields.map((field) => computeTotal(date, data[field]));
  if (areValid(...values)) {
    return computeFn(values);
  }
};

const computeTotalWithGetters = (date, data, computeFn, ...fields) => {
  const values = fields.map((field) =>
    Object.keys(Object.values(data)[0] || {})
      .map((client) => {
        const { valueGetter } = tables[field];
        return valueGetter ? valueGetter(date, data, client) : getValue({ data, field, client, date });
      })
      .reduce((sum, val) => sum + (val || 0), 0)
  );
  if (areValid(...values)) {
    return computeFn(values);
  }
};

const getValue = ({ data, field, client, date }, ignoreValueGetter) => {
  const { valueGetter } = tables[field];
  return valueGetter && !ignoreValueGetter ? valueGetter(date, data, client) : data[field]?.[client]?.[date]?.value;
};

const computeValue = (date, data, client, computeFn, ...fields) => {
  const values = fields.map((field) => getValue({ data, field, client, date }));
  if (areValid(...values)) {
    return computeFn(values);
  }
};

const computeProduct = (date, data, client, ...fields) =>
  computeValue(date, data, client, (values) => values.reduce((sum, value) => sum * value, 1), ...fields);

const computeValueFrom = (date, data, table, computeFn, ...fields) => {
  const values = fields.map((field) => tables[table].rows[field].valueGetter(date, data));
  if (areValid(...values)) {
    return computeFn(values);
  }
};

const computeValueFromSummary = (date, data, computeFn, ...fields) =>
  computeValueFrom(date, data, "financialSummary", computeFn, ...fields);

const computeMomIncrease = (date, data, field) => {
  if (isFirstMonth(date)) {
    return "NA";
  }
  const getValue = (dataDate) =>
    computeValueFromSummary(
      dataDate,
      data,
      ([margin, opening, closing]) => divide([margin, sum([opening, closing]) / 2]),
      field,
      "_openingHeadcount",
      "_closingAssociateHeadcount"
    );
  const curr = getValue(date);
  const prev = getValue(getPreviousMonth(date));
  return divide([curr - prev, curr]);
};

export const tables = {
  financialSummary: {
    title: "Financial summary",
    rows: {
      _openingHeadcount: {
        name: "Opening Headcount (for all clients in your area)",
        valueGetter: (date, data) => {
          if (isFirstMonth(date)) {
            return computeTotal(date, data["clientwiseOpeningHeadcount"]);
          }
          return computeValueFromSummary(getPreviousMonth(date), data, getFirst, "_closingAssociateHeadcount");
        },
      },
      _netAdditions: {
        name: "Net additions",
        valueGetter: (date, data) => {
          return computeTotal(date, data["netAdditions"]);
        },
      },
      _closingAssociateHeadcount: {
        name: "Closing associate headcount",
        valueGetter: (date, data) => computeValueFromSummary(date, data, sum, "_openingHeadcount", "_netAdditions"),
      },
      _totalBilling: {
        name: "Total Billing (adjusted for joining) in lakhs",
        valueGetter: (date, data) => computeTotalWithGetters(date, data, getFirst, "clientMonthlyBilling"),
      },
      _totalConsultantSalaries: {
        name: "Total Consultant Salaries (adjusted) in lakhs",
        valueGetter: (date, data) => computeTotalWithGetters(date, data, getFirst, "directCostOfConsultants"),
      },
      _grossMargin: {
        name: "Gross Margin",
        valueGetter: (date, data) =>
          computeValueFromSummary(date, data, subtract, "_totalBilling", "_totalConsultantSalaries"),
      },
      _grossMarginPercent: {
        name: "Gross Margin %",
        valueGetter: (date, data) => computeValueFromSummary(date, data, divide, "_grossMargin", "_totalBilling"),
        valueFormatter: percentFormatter,
      },
      _coreEmployeeHeadcount: {
        name: "Core Employee Headcount",
        valueGetter: (date, data) =>
          computeTotalWith(
            date,
            data,
            (vals) => sum(vals) * 1.1,
            "recruitersForFulfilling",
            "numTeamleaders",
            "numAccountManager"
          ),
      },
      _coreEmployeeSalaries: {
        name: "Core Employee Salaries (lakhs) including PI",
        valueGetter: (date, data) =>
          computeTotalWith(
            date,
            data,
            (vals) => (sum(vals) * 1.2) / 100000,
            "totalRecruiterCost",
            "totalTeamLeaderCost",
            "totalAccountManagerCost"
          ),
      },
      _operatingExpenses: {
        name: "Operating Expenses (lakhs)",
        valueGetter: (date, data) => {
          return computeTotal(date, data["operatingExpenseHeads"]);
        },
      },
      _salesMarketingOverheads: {
        name: "Sales, Marketing, Overheads (lakhs)",
        valueGetter: () => 5,
      },
      _operatingExpenseTotal: {
        name: "Operating Expenses Total (lakhs)",
        valueGetter: (date, data) =>
          computeValueFromSummary(
            date,
            data,
            sum,
            "_coreEmployeeSalaries",
            "_operatingExpenses",
            "_salesMarketingOverheads"
          ),
      },
      _ebidta: {
        name: "EBIDTA",
        valueGetter: (date, data) =>
          computeValueFromSummary(date, data, subtract, "_grossMargin", "_operatingExpenseTotal"),
      },
      _ebidtaPercent: {
        name: "EBIDTA %",
        valueGetter: (date, data) => computeValueFromSummary(date, data, divide, "_ebidta", "_totalBilling"),
        valueFormatter: percentFormatter,
      },
      _interestOnCapital: {
        name: "Interest on Working Capital (lakhs)",
        valueGetter: () => 3,
      },
      _interest: {
        name: "Interest (, Akash's inputs in lakhs)",
        valueGetter: () => 0,
      },
      _depreciation: {
        name: "Depreciation (, Akash's inputs in lakhs)",
        valueGetter: () => 1,
      },
      _pbt: {
        name: "PBT",
        valueGetter: (date, data) =>
          computeValueFromSummary(
            date,
            data,
            ([ebidta, ...rest]) => ebidta - sum(rest),
            "_ebidta",
            "_interestOnCapital",
            "_interest",
            "_depreciation"
          ),
      },
      _pbtPercent: {
        name: "PBT %",
        valueGetter: (date, data) => computeValueFromSummary(date, data, divide, "_pbt", "_totalBilling"),
        valueFormatter: percentFormatter,
      },
      _pat: {
        name: "~ PAT",
        valueGetter: (date, data) => computeValueFromSummary(date, data, ([pbt]) => pbt * 0.67, "_pbt"),
      },
      _patPercent: {
        name: "~ PAT %",
        valueGetter: (date, data) => computeValueFromSummary(date, data, divide, "_pat", "_totalBilling"),
        valueFormatter: percentFormatter,
      },
      _netMarginPercent: {
        name: "Net margin %",
        valueGetter: (date, data) => computeValueFromSummary(date, data, divide, "_pat", "_grossMargin"),
        valueFormatter: percentFormatter,
      },
    },
  },
  operationalSummary: {
    title: "Operational summary",
    rows: {
      _openDemand: {
        name: "% Open demand serviced",
        valueGetter: (date, data) =>
          computeTotalWith(date, data, divide, "grossAdditionsByRecruiters", "expectedDemand"),
      },
      _recruiterProductivity: {
        name: "Average recruiter productivity",
        valueGetter: (date, data) => computeTotal(date, data["avgProductivityOfRecruiters"]),
      },
      _coreEmployeeToAssociate: {
        name: "Core employee to Associate ratio",
        valueGetter: (date, data) =>
          computeValueFromSummary(date, data, divide, "_closingAssociateHeadcount", "_coreEmployeeHeadcount"),
      },
      _overallProductivity: {
        name: "Overall org productivity",
        valueGetter: (date, data) =>
          divide([
            computeTotal(date, data["grossAdditionsByRecruiters"]),
            computeValueFromSummary(date, data, getFirst, "_coreEmployeeHeadcount"),
          ]),
      },
      _recruitingCost: {
        name: "Recruiting cost per gross addition",
        valueFormatter: currencyFormatter,
        valueGetter: (date, data) => {
          return computeTotalWithGetters(date, data, divide, "totalRecruiterCost", "grossAdditionsByRecruiters");
        },
      },
      _managementCost: {
        name: "Management cost per gross addition",
        valueFormatter: currencyFormatter,
        valueGetter: (date, data) => {
          const cost = computeValueFromSummary(date, data, sum, "_coreEmployeeSalaries", "_salesMarketingOverheads");
          return computeTotalWithGetters(
            date,
            data,
            ([recruiterCost, additions]) => divide([cost * 100000 - recruiterCost, additions]),
            "totalRecruiterCost",
            "grossAdditionsByRecruiters"
          );
        },
      },
      _overheadCost: {
        name: "Overhead cost per gross addition",
        valueFormatter: currencyFormatter,
        valueGetter: (date, data) => {
          const cost = computeValueFromSummary(date, data, sum, "_operatingExpenses", "_salesMarketingOverheads");
          return computeTotalWithGetters(
            date,
            data,
            ([additions]) => divide([cost * 100000, additions]),
            "grossAdditionsByRecruiters"
          );
        },
      },
      _totalCost: {
        name: "Total cost per gross hire",
        valueFormatter: currencyFormatter,
        valueGetter: (date, data) =>
          computeValueFrom(
            date,
            data,
            "operationalSummary",
            sum,
            "_recruitingCost",
            "_managementCost",
            "_overheadCost"
          ),
      },
      _billingToCost: {
        name: "Billing to cost ratio",
        valueGetter: (date, data) =>
          computeValueFromSummary(date, data, divide, "_totalBilling", "_operatingExpenseTotal"),
      },
      _grossMarginToCost: {
        name: "Gross margin to cost ratio",
        valueGetter: (date, data) =>
          computeValueFromSummary(date, data, divide, "_grossMargin", "_operatingExpenseTotal"),
      },
      _recruitersPerTeamlead: {
        name: "# of recruiters per team lead",
        valueGetter: (date, data) => computeTotalWith(date, data, divide, "recruitersForFulfilling", "numTeamleaders"),
      },
      _teamleadsPerAccountManager: {
        name: "# of team leads per account manager",
        valueGetter: (date, data) => computeTotalWith(date, data, divide, "numTeamleaders", "numAccountManager"),
      },
      _recruitersPerAccountManager: {
        name: "# of recruiters per account manager",
        valueGetter: (date, data) =>
          computeTotalWith(date, data, divide, "recruitersForFulfilling", "numAccountManager"),
      },
      _increaseInGM: {
        name: "MoM increase in GM per HC",
        valueGetter: (date, data) => computeMomIncrease(date, data, "_grossMargin"),
      },
      _increaseInCost: {
        name: "MoM increase in cost per HC",
        valueGetter: (date, data) => computeMomIncrease(date, data, "_operatingExpenseTotal"),
      },
    },
  },
  expectedDemand: {
    title: "Expected demand from client segments",
  },
  recruitersForFulfilling: {
    title: "Number of recruiters for fulfilling above demand",
  },
  grossAdditionsByRecruiters: {
    title: "Gross additions by recruiters based on this productivity",
    aggAvg: true,
  },
  avgProductivityOfRecruiters: {
    title: "Average productivity of the recruiters",
    valueGetter: (date, data, client) =>
      computeValue(date, data, client, divide, "grossAdditionsByRecruiters", "recruitersForFulfilling"),
  },
  expectedExits: {
    title: "Expected exits from each of the clients for the month",
  },
  netAdditions: {
    title: "Net additions for the month",
    valueGetter: (date, data, client) =>
      computeValue(date, data, client, subtract, "grossAdditionsByRecruiters", "expectedExits"),
  },
  avgBillingRate: {
    title: "Average billing rate for each of these client segments",
    valueFormatter: currencyFormatter,
    description: "Full figure INR, no decimals. Factor price increase / decrease. This is billing per consultant.",
    aggAvg: true,
  },
  clientMonthlyBilling: {
    title: "Client wise monthly billing",
    valueFormatter: currencyFormatter,
    valueGetter: (date, data, client) => computeProduct(date, data, client, "avgHeadcount", "avgBillingRate"),
  },
  avgExpectedSalaryOfConsultant: {
    title: "Average expected monthly consultant salary",
    valueFormatter: currencyFormatter,
    description: "Full figure INR, no decimals. Factor price increase / decrease. This is Monthly CTC per consultant.",
    aggAvg: true,
  },
  directCostOfConsultants: {
    title: "Client wise, month wise, direct cost of consultants",
    valueFormatter: currencyFormatter,
    valueGetter: (date, data, client) =>
      computeProduct(date, data, client, "avgHeadcount", "avgExpectedSalaryOfConsultant"),
  },
  grossMarginPercent: {
    title: "Gross Margin % for each client segment",
    valueGetter: (date, data, client) =>
      computeValue(
        date,
        data,
        client,
        ([rate, salary]) => (rate - salary) / rate,
        "avgBillingRate",
        "avgExpectedSalaryOfConsultant"
      ),
    valueFormatter: percentFormatter,
    aggAvg: true,
  },
  numTeamleaders: {
    title: "Number of TeamLeaders allocated for client segment",
  },
  numAccountManager: {
    title: "Number of Account Manager is allocated for client segment",
  },
  expectedAvgSalaryOfRecruiters: {
    title: "Expected average salary of recruiter allocated for client",
    valueFormatter: currencyFormatter,
    description: "Please enter Values in full rupees",
    aggAvg: true,
  },
  expectedAvgSalaryOfTeamLeader: {
    title: "Expected average salary of TeamLeader allocated for client",
    valueFormatter: currencyFormatter,
    description: "Please enter Values in full rupees",
    aggAvg: true,
  },
  expectedAvgSalaryOfAccountManager: {
    title: "Expected average salary of Account Manager allocated for client",
    valueFormatter: currencyFormatter,
    description: "Please enter Values in full rupees",
    aggAvg: true,
  },
  operatingExpenseHeads: {
    title: "All Operating Expense heads (Specify major, club rest)",
    valueFormatter: currencyFormatter,
    rows: {
      _rent: {
        name: "Rent",
      },
      _infraCost: {
        name: "Infrastructure Cost excluding Rent",
      },
      _adminExpenses: {
        name: "Other Administrative Expenses",
      },
      _jobPortal: {
        name: "Job Portal",
      },
    },
  },
  overheadSalaries: {
    title: "Sales, Marketing, PR, Overhead salaries",
    valueFormatter: currencyFormatter,
    rows: {
      _incentives: {
        name: "Incentives",
      },
      _bdLogistics: {
        name: "BD Logistics",
      },
      _candidateRelocation: {
        name: "Candidate Relocation Charges",
      },
    },
  },
  totalRecruiterCost: {
    title: "Total recruiter cost",
    valueFormatter: currencyFormatter,
    valueGetter: (date, data, client) =>
      computeProduct(date, data, client, "recruitersForFulfilling", "expectedAvgSalaryOfRecruiters"),
  },
  totalTeamLeaderCost: {
    title: "Total TeamLeader cost",
    valueFormatter: currencyFormatter,
    valueGetter: (date, data, client) =>
      computeProduct(date, data, client, "numTeamleaders", "expectedAvgSalaryOfTeamLeader"),
  },
  totalAccountManagerCost: {
    title: "Total Account manager cost",
    valueFormatter: currencyFormatter,
    valueGetter: (date, data, client) =>
      computeProduct(date, data, client, "numAccountManager", "expectedAvgSalaryOfAccountManager"),
  },
  clientwiseClosingHeadcount: {
    title: "Clientwise closing headcount of people",
    isEditable: isFirstMonth,
    valueGetter: (date, data, client) => {
      if (isFirstMonth(date)) {
        return getValue({ field: "clientwiseClosingHeadcount", data, client, date }, true);
      }
      const additions = tables.netAdditions.valueGetter(date, data, client);
      const headcount = tables.clientwiseClosingHeadcount.valueGetter(getPreviousMonth(date), data, client);
      return sum([additions, headcount]);
    },
  },
  clientwiseOpeningHeadcount: {
    title: "Clientwise opening headcount",
    isEditable: isFirstMonth,
    valueGetter: (date, data, client) => {
      if (isFirstMonth(date)) {
        return getValue({ field: "clientwiseOpeningHeadcount", data, client, date }, true);
      }
      return tables.clientwiseClosingHeadcount.valueGetter(getPreviousMonth(date), data, client);
    },
  },
  avgHeadcount: {
    title: "Average headcount for the month",
    valueGetter: (date, data, client) => {
      const closing = tables.clientwiseClosingHeadcount.valueGetter(date, data, client);
      const opening = tables.clientwiseOpeningHeadcount.valueGetter(date, data, client);
      if (areValid(closing, opening)) {
        return (closing + opening) / 2;
      }
    },
  },
};
