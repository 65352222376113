import { ConfigProvider } from "antd";
import en_GB from "antd/locale/en_GB";
import { Provider } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { store } from "../../redux/store";
import { Login } from "../login";
import { MainView } from "../main-view";
import { PrivateRoute } from "./PrivateRoute";

function App() {
  return (
    <ConfigProvider locale={en_GB}>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route
              path="/tables"
              element={
                <PrivateRoute>
                  <MainView />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<Navigate to="/tables" />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </ConfigProvider>
  );
}

export default App;
