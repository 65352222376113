import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { del, get, post } from "../utils/fetch";

export const fetchTagCategories = createAsyncThunk("tagCategories/fetch", async (_, { rejectWithValue }) => {
  try {
    const categories = await get("tag-categories");
    return categories.map(({ _id, ...category }) => ({ value: _id, ...category }));
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const addTagCategory = createAsyncThunk("tagCategories/add", async (label, { rejectWithValue }) => {
  try {
    const category = await post("tag-categories", { label });
    return { ...category, label };
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteTagCategory = createAsyncThunk("tagCategories/delete", async (value, { rejectWithValue }) => {
  try {
    await del(`tag-categories/${value}`);
    return { value };
  } catch (err) {
    return rejectWithValue(err);
  }
});

const tagCategoriesSlice = createSlice({
  name: "tagCategories",
  initialState: [],
  extraReducers: (builder) => {
    builder.addCase(fetchTagCategories.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(addTagCategory.fulfilled, (state, { payload }) => {
      state.push({ value: payload.insertedId, label: payload.label });
    });
    builder.addCase(deleteTagCategory.fulfilled, (state, { payload }) => {
      return state.filter((category) => category.value !== payload.value);
    });
  },
});

export default tagCategoriesSlice.reducer;
